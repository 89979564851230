
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { Crumb } from '@/store/resources/form/state'
  import { mapActions } from 'vuex'

@Component({
  components: {},
  methods: {
    ...mapActions('resources/form', ['setCrumbs']),
  },
  computed: {},
})
  export default class GBreadcrumbs extends Vue {
  @Prop({ type: Array, default: () => [] }) crumbs!: Array<Crumb>

  setCrumbs!: (crumbs: Array<Crumb>) => void

  setCrumbsModified (newCrumbs) {
    return newCrumbs.map((crumb, index) => this.setCrumbProperties(crumb, index, newCrumbs.length))
  }

  setCrumbProperties (crumb, index, totalCrumbs) {
    const isLast = this.determineIsLast(index, totalCrumbs)
    const isDisabled = this.determineIsDisabled(index, totalCrumbs)

    return { ...crumb, isLast, disabled: isDisabled }
  }

  determineIsLast (index, totalCrumbs) {
    return totalCrumbs > 1 ? index === totalCrumbs - 2 : false
  }

  determineIsDisabled (index, totalCrumbs) {
    return totalCrumbs > 1 && index === totalCrumbs - 1
  }

  goBack () {
    const { crumbs } = this

    this.setCrumbs(crumbs.slice(0, -1))
    return this.$router.back()
  }
  }
